import React from "react";
import { Container } from "reactstrap";
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";
import SubHeading from "../alter3/SubHeading.jsx";
class CareersDetails extends React.Component {
  text = {
    title: "Careers",
    subtitle: "Work with us!",
  };



  
  componentDidMount() {}
  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          <SubHeading title={this.text.title} subtitle={this.text.subtitle} />
          <section className="section why-choose-us ">
            <Container className="bring-to-front ">

            


            {this.props.jobpos === "dataengineer" && (
                <div className="careerFront ">
                  <h5 className="bold text-alternate">
                  Data Engineer

                  </h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                      Estamos em busca de uma pessoa que será responsável pela conferência de Streams de dados, e entrada de dados dos sistemas da empresa para o nosso Data Lake. Irá ler e por vezes modificar códigos em Elixir nos sistemas da empresa que consomem e enviam dados via Kafka.


                      </p>
                     
                      <strong>Requisitos </strong>
                      <p>
                        <ul>
                        <li>  Kafka é diferencial, ou Snowflake </li> 
                        <li>Elixir</li>
                        <li>Sênior (ao menos 5 anos de experiência)</li>
                        <li>Python/Node</li>
                        <li>Tableau</li>
                        <li>ETL</li>
                        <li>Databricks</li>
<li>Experiência com pipeline de dados</li>
<li>Inglês avançado</li>
      
                           

                        
                        </ul>
                      </p>
                     
                      <strong>Benefícios</strong>

                      <ul>
                        <li> Modalidade PJ</li>{" "}
                        <li>Aulas de inglês Básico, Avançado e Conversação</li>{" "}
                        <li>10 dias pagos de férias</li>{" "}
                        <li>Horário flexível</li> <li>Home Office</li>{" "}


                        <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                        <li>
                          Webinars e ajuda de profissional para Saúde Mental
                        </li>{" "}
                        <li>Folga em dias específicos previamente planejado</li>
                      </ul>
                    </p>
                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}





{this.props.jobpos === "fullstackphpsr" && (
                <div className="careerFront ">
                  <h5 className="bold text-alternate">
                  FullStack SR  (React/Node) 
                  </h5>
                  <div className="card-body ">
              
                      <strong>Sobre a Vaga</strong>
                      <p>
                      Estamos em busca de uma pessoa com sólidos conhecimentos em NodeJs e React, para atuar em projeto internacional, com o desenvolvimento de uma plataforma voltada para automatização de processos com IA.
                      </p>
                    
                      <strong>Requisitos </strong>
                      <p>

                        <ul>
                          <li>Conhecimentos em manipulação de dados</li>
                          <li>Integrações com APIs</li>
                          <li>Inglês Avançado</li>
                        </ul>
                     
                     
                        <strong>Diferenciais</strong>
                          <p>
                            <li>Conhecimentos em Inteligência Artificial </li>
                            <li>Conhecimentos em Python  </li>
                            <li>Conhecimentos em Google Cloud Functions  </li>

                          </p>
                          
                     
                  
                      <strong>Benefícios</strong>

                      <ul>
                        <li> Modalidade PJ</li>{" "}
                        <li>Aulas de inglês Básico, Avançado e Conversação</li>{" "}
                        <li>10 dias pagos de férias</li>{" "}
                        <li>Horário flexível</li> <li>Home Office</li>{" "}


                        <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                        <li>
                          Webinars e ajuda de profissional para Saúde Mental
                        </li>{" "}
                        <li>Folga em dias específicos previamente planejado</li>
                        <li>Convênio com Wellhub (Gympass)</li> 
                      </ul>
                    </p>
                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}

              {this.props.jobpos === "backendnode" && (
                <div className="careerFront ">
                  <h5 className="bold text-alternate">
                  Backend Developer: Node.JS
                  </h5>
                  <div className="card-body ">
              
                      <strong>Sobre a Vaga</strong>
                      <p>
                      Estamos em busca de uma pessoa que será responsável por coletar dados e sites e ou sistemas diversos.   </p>
                    
                      <strong>Requisitos </strong>
                      <p>

                        <ul>
                      <li>  Node.JS com Typescript</li>
                      <li>Playwright e WebScraping para mineração de dados</li>
                      <li>Banco de dados SQL</li>
                      <li>Google Cloud Functions</li>
                      <li>Inglês fluente</li>

                     
                        
                        </ul>
                     
                     
                        <strong>Diferencial</strong>
                          <p>
                            <li>Conhecimentos em Tailwindcss  </li>

                          </p>
                          
                     
                  
                      <strong>Benefícios</strong>

                      <ul>
                        <li> Modalidade PJ</li>{" "}
                        <li>Aulas de inglês Básico, Avançado e Conversação</li>{" "}
                        <li>10 dias pagos de férias</li>{" "}
                        <li>Horário flexível</li> <li>Home Office</li>{" "}


                        <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                        <li>
                          Webinars e ajuda de profissional para Saúde Mental
                        </li>{" "}
                        <li>Folga em dias específicos previamente planejado</li>
                        <li>Convênio com Wellhub (Gympass)</li> 
                      </ul>
                    </p>
                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}


          {this.props.jobpos === "frontendreact" && (
                <div className="careerFront ">
                  <h5 className="bold text-alternate">
                  Frontend Developer: React
                  </h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                      Estamos em busca de uma pessoa que será responsável pelo site da empresa e plataforma que os clientes acessam. Também irá transformar mockups em telas ou páginas no sistema.</p>
                    
                      <strong>Requisitos </strong>
                      <p>
                      <ul>
                  <li>    ReactJS  com Typescript</li>
                  <li>    Banco de dados SQL</li>
                  <li>    Integração com APIs REST (GraphQL é diferencial)</li>
                  <li>    CSS</li>
                  <li>    Experiência com bibliotecas de estatística e gráficos é diferencial</li>
          
                  <li>    Inglês fluente</li>    
                        
         
                        </ul>
                      </p>
                     
                      <strong>Benefícios</strong>

                      <ul>
                        <li> Modalidade PJ</li>{" "}
                        <li>Aulas de inglês Básico, Avançado e Conversação</li>{" "}
                        <li>10 dias pagos de férias</li>{" "}
                        <li>Horário flexível</li> <li>Home Office</li>{" "}


                        <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                        <li>
                          Webinars e ajuda de profissional para Saúde Mental
                        </li>{" "}
                        <li>Folga em dias específicos previamente planejado</li>
                        <li>Convênio com Wellhub (Gympass)</li> 
                      </ul>
                    </p>
                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}






              {this.props.jobpos === "virtualassistant" && (
                <div className="careerFront">
                  <h5 className="bold text-alternate">
                  Virtual Assistant
                  </h5>
                  <div className="card-body ">
                    <p>
                      <p>
                        Estamos com uma oportunidade para uma pessoa atuar de forma remota no atendimento a clientes em diversos canais digitais.
                        <br />
                      </p>
                    
                      <p>
                        <ul>
                      <li>  Experiências com ferramentas do Google, pacote office</li>
                      <li>Experiências com gestão em mídias sociais, e-mail, agenda, compromissos</li>
                      <li>Inglês fluente</li>
                        </ul>
                        <strong>Benefícios</strong>
                      </p>
                      <ul>
                        <li> Contrato PJ;</li>{" "}
                        <li>Aulas de inglês avançado para empresas;</li>{" "}
                        <li>10 dias pagos de férias;</li>{" "}
                        
                        <li>Home Office;</li>{" "}


                        <li>PLR Mensal;</li>
                        <li>Cursos e Certificações;</li>{" "}
                        <li>
                          Folga em dias específicos previamente planejado;
                        </li>
                        <li>
                          Descontos e/ou Benefícios com empresas parceiras;{" "}
                        </li>
                        <li>
                          Webinars e ajuda de profissionais para Saúde Mental;
                        </li>
                      </ul>
                    </p>
                  </div>
                  <div>
                    <strong>Vem crescer com a gente!</strong> <br />
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}




              
{this.props.jobpos === "marketing" && (
                <div className="careerFront">
                  <h5 className="bold text-alternate">Marketing Specialist</h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                      Estamos em busca de uma pessoa especialista em marketing para atuar com um time internacional de uma empresa de grande porte do ramo de turismo especializada em cruzeiros.

                        <br /> <br />

                        O especialista em operações de e-commerce e marketing desempenhará um papel
papel fundamental na otimização da marca digital da empresa e na melhoria da interação do cliente.
<br/><br/>
Essa função multifacetada abrange:
                      </p>

                      <p>
                        <ul>

                         
                          <li>SEO, automação de vendas e marketing;</li>
                          <li>Gerenciamento de CMS;</li>
                          <li>Testes de usuárioso</li>
                          <li> Garantia de qualidade;</li>
                          <li>Análise de dados;</li>
                          <li>Habilidades básicas de codificação para manutenção e melhoria do site (HTML e CSS);</li>

                          </ul>
                      </p>

                      <strong>Requisitos</strong>
                      <p>
                        <ul>
                      
                          <li> Inglês Avançado</li>
                          <li>  Experiência com estratégias de SEO, vendas e ferramentas de automação de marketing (HubSpot, Klaviyo...)</li>
                          <li>  Experiência com plataformas CMS (Drupal, Shopify, Magento, WordPress...)</li>
                          <li>Familiaridade com ferramentas de análise de dados (Google Tag Manager, Google Analytics...)</li>
                            
                        </ul>
                      </p>


                      <strong>Benefícios</strong>
                      <p>
                        <ul>
                          <li> Modalidade PJ</li>{" "}
                          <li>Aulas de inglês avançado para empresas</li>{" "}
                          <li>10 dias pagos de férias</li>{" "}
                          <li>Horário flexível</li> <li>Home Office</li>{" "}


                          <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                          <li>
                            Webinars e ajuda de profissional para Saúde Mental
                          </li>{" "}
                          <li>
                            Folga em dias específicos previamente planejado
                          </li>
                          <li>
                            Descontos e/ou Benefícios com empresas parceiras{" "}
                          </li>
                        </ul>
                      </p>
                    </p>

                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}


              
{this.props.jobpos === "tracking" && (
                <div className="careerFront">
                  <h5 className="bold text-alternate">Tracking Analyst</h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                      Buscamos uma pessoa para atuar como Tracking Analyst em um projeto norte-americano do ramo de hotelaria e turismo para monitorar e analisar dados de rastreamento e fazer integrações de front-end.
                        <br /> <br />

</p>
                      <strong>Requisitos</strong>
                      <p>
                        <ul>
                      
                          <li> Inglês avançado para comunicação direta com o cliente;</li>
                          <li>Experiência com ferramentas de gerenciamento e análise de dados (Google Tag Manager, Google Analytics...)</li>
                          <li>Experiência com ferramentas de gerenciamento de campanhas ( Google Adwords, Bing Ads...)</li>
                          <li>Experiência com plataformas de experiência digital (Bloomreach))</li>
                          <li>Experiência com plataformas de monitoramento e análise de dados em tempo real (Datadog)</li>
                          <li>Conhecimentos JavaScript (React é um diferencial)</li>

                        </ul>
                      </p>


                      <strong>Benefícios</strong>
                      <p>
                        <ul>
                          <li> Modalidade PJ</li>{" "}
                          <li>Aulas de inglês avançado para empresas</li>{" "}
                          <li>10 dias pagos de férias</li>{" "}
                          <li>Horário flexível</li> <li>Home Office</li>{" "}


                          <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                          <li>
                            Webinars e ajuda de profissional para Saúde Mental
                          </li>{" "}
                          <li>
                            Folga em dias específicos previamente planejado
                          </li>
                          <li>
                            Descontos e/ou Benefícios com empresas parceiras{" "}
                          </li>
                        </ul>
                      </p>
                    </p>

                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}



{this.props.jobpos === "scrummaster" && (
                <div className="careerFront">
                  <h5 className="bold text-alternate">Scrum Master</h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                      Buscamos uma pessoa com sólida experiência em metodologias ágeis (especialmente em Scrum) que será responsável por facilitar o desenvolvimento de maneira Ágil, garantindo a entrega eficaz de produtos de alta qualidade. </p>

                      <strong>Requisitos</strong>
                      <p>
                        <ul>
                      
                          <li> Inglês Avançado;</li>
                          <li> Experiência comprovada em papéis de liderança de times ágeis;</li>
                          <li> Excelentes habilidades de comunicação, facilitação e resolução de problemas, capazes de lidar com desafios multiculturais e linguísticos;</li>
                          <li> Foco em entrega de valor ao cliente e paixão por promover uma cultura ágil de alta performance; </li>

                            
                        </ul>
                      </p>


                      <strong>Benefícios</strong>
                      <p>
                        <ul>
                          <li> Modalidade PJ</li>{" "}
                          <li>Aulas de inglês avançado para empresas</li>{" "}
                          <li>10 dias pagos de férias</li>{" "}
                          <li>Horário flexível</li> <li>Home Office</li>{" "}


                          <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                          <li>
                            Webinars e ajuda de profissional para Saúde Mental
                          </li>{" "}
                          <li>
                            Folga em dias específicos previamente planejado
                          </li>
                          <li>
                            Descontos e/ou Benefícios com empresas parceiras{" "}
                          </li>
                        </ul>
                      </p>
                    </p>

                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}



              
              {this.props.jobpos === "datascience" && (
                <div className="careerFront">
                  <h5 className="bold text-alternate">Data Science Engineer</h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                        Estamos em busca de pessoas que possam projetar, desenvolver e manter pipelines de dados, criar visualizações e dashboards, criar modelos preditivos e melhorar sistemas de recomendação para apoiar a tomada de decisões baseadas em dados.
                        <br /> <br />

                        Então se você tem vontade de trabalhar com pessoas
                        incríveis, em um ambiente colaborativo, ágil e inovador,
                        temos a oportunidade certa para você!
                      </p>
                     
                      <strong>Requisitos</strong>
                      <p>
                        <ul>



                         
                          <li> Inglês Intermediário / Avançado</li>
                          <li>  Sólida experiência em linguagens de programação como Python e SQL;</li>
                          <li>  Experiência no desenvolvimento de pipelines de dados e no trabalho com big data;</li>
                          <li> Experiência com Tecnologias Databricks, Apache Spark, Hadoop, Snowflake;</li>
                                <li>  Proficiência em técnicas e bibliotecas de machine learning (scikit-learn, TensorFlow, PyTorch);</li>
                          <li>  Experiência com bancos de dados de pesquisa vetorial (Elasticsearch, Pinecone) e com sistemas de recomendação <strong>serão considerados diferenciais</strong>;</li>
                          
                        </ul>
                      </p>


                      <strong>Benefícios</strong>
                      <p>
                        <ul>
                          <li> Modalidade PJ</li>{" "}
                          <li>Aulas de inglês avançado para empresas</li>{" "}
                          <li>10 dias pagos de férias</li>{" "}
                          <li>Horário flexível</li> <li>Home Office</li>{" "}


                          <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                          <li>
                            Webinars e ajuda de profissional para Saúde Mental
                          </li>{" "}
                          <li>
                            Folga em dias específicos previamente planejado
                          </li>
                          <li>
                            Descontos e/ou Benefícios com empresas parceiras{" "}
                          </li>
                        </ul>
                      </p>
                    </p>

                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}



              {this.props.jobpos === "qa" && (
                <div className="careerFront">
                  <h5 className="bold text-alternate">Analista de Qualidade</h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                        Buscamos pessoas com experiência em testes de qualidade (tanto manuais quanto automatizados), que tenham facilidade de se comunicar em inglês e muita vontade de se desenvolver profissionalmente.
                        <br /> <br />
                       
                        Então se você tem vontade de trabalhar com pessoas
                        incríveis, em um ambiente colaborativo, ágil e inovador,
                        temos a oportunidade certa para você!
                      </p>
                      <strong>Responsabilidades</strong>
                      <p>
                        <ul>
                          <li>
                            Execução dos planos de testes exploratórios, registrando a
                            ocorrência de bugs e melhorias;
                          </li>
                         
                          <li>
                            Trabalhar respeitando os processos ágeis de
                            desenvolvimento.
                          </li>
                        </ul>
                      </p>
                      <strong>Requisitos</strong>
                      <p>
                        <ul>
                         

                           
                          <li>
                            Inglês intermediário
                          </li>
                        </ul>
                      </p>
                      

                      <strong>Benefícios</strong>
                      <p>
                        <ul>
                          <li> Modalidade PJ</li>{" "}
                          <li>Aulas de inglês avançado para empresas</li>{" "}
                          <li>10 dias pagos de férias</li>{" "}
                          <li>Horário flexível</li> <li>Home Office</li>{" "}


                          <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                          <li>
                            Webinars e ajuda de profissional para Saúde Mental
                          </li>{" "}
                          <li>
                            Folga em dias específicos previamente planejado
                          </li>
                          <li>
                            Descontos e/ou Benefícios com empresas parceiras{" "}
                          </li>
                        </ul>
                      </p>
                    </p>

                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}

              {this.props.jobpos === "react" && (
                <div className="careerFront ">
                  <h5 className="bold text-alternate">React Developer</h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                        Um dos nossos projetos norte-americanos da área da
                        Construção Civil está desenvolvendo um software
                        inovador, que visa resolver diversos problemas no setor,
                        e procura por mais desenvolvedores para integrar o time!
                        O time no Brasil conta com 8 desenvolvedores, QAs e
                        Scrum Master. Então, se você tem vontade de trabalhar
                        com pessoas incríveis, em um ambiente colaborativo, ágil
                        e inovador, temos a oportunidade certa para você!
                        <br />
                      </p>
                      <strong>Responsabilidades</strong>
                      <p>
                        Buscamos por uma pessoa proativa, que colabore com a
                        resolução de problemas do time, incluindo
                        Pair-Programming se preciso. Será necessário reportar
                        diariamente o andamentos das atividades, respeitar os
                        processos ágeis de desenvolvimento, apontar melhorias e
                        e desenvolver utilizando principalmente linguagens como
                        Javascript, ReactJS e/ou ReactNative.
                      </p>
                      <strong>Requisitos</strong>
                      <p>
                        <ul>
                          <li>
                            A partir de 5 anos de experiência com Javascript no
                            front-end
                          </li>
                          <li>
                            A partir de 2 anos de experiência com React e/ou
                            React Native
                          </li>
                          <li>
                            Conhecimentos em Banco de Dados MySQL ou PostgreSQL
                          </li>
                        </ul>
                        <strong>Diferenciais</strong>
                        <p>
                          <li>Inglês intermediário ou avançado</li>
                        </p>
                        <strong>Benefícios</strong>
                      </p>
                      <ul>
                        <li> Modalidade PJ</li>{" "}
                        <li>Aulas de inglês avançado para empresas</li>{" "}
                        <li>10 dias pagos de férias</li>{" "}
                        <li>Horário flexível</li> <li>Home Office</li>{" "}


                        <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                        <li>
                          Webinars e ajuda de profissional para Saúde Mental
                        </li>{" "}
                        <li>Folga em dias específicos previamente planejado</li>
                      </ul>
                    </p>
                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}


              {this.props.jobpos === "fullstack" && (
                <div className="careerFront ">
                  <h5 className="bold text-alternate">
                    FullStack Developer Pl. | Sr.
                  </h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                        Esse cliente já está há mais de 20 anos no mercado norte-americano, operando em 6 estados.
                        O projeto tem como objetivo digitalizar, automatizar e otimizar os processos da empresa que conta com mais de 300 mil usuários ativos.

                      </p>
                      <strong>Responsabilidades</strong>
                      <p>
                        <ul>
                          <li>
                        
                            O principal objetivo será de desenvolver novas funcionalidades para os produtos.
                          </li>
                        </ul>
                      </p>
                      <strong>Requisitos </strong>
                      <p>
                        <ul>
                          <li>Experiencia com React</li>
                          <li> Experiência com PHP e seus frameworks Laravel ou Symfony</li>
                          <li> Conhecimentos nos bancos de dados Postgres</li>
                            <li> Inglês intermediário.</li>

                        
                        </ul>
                      </p>
                     
                      <strong>Benefícios</strong>

                      <ul>
                        <li> Modalidade PJ</li>{" "}
                        <li>Aulas de inglês Básico, Avançado e Conversação</li>{" "}
                        <li>10 dias pagos de férias</li>{" "}
                        <li>Horário flexível</li> <li>Home Office</li>{" "}


                        <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                        <li>
                          Webinars e ajuda de profissional para Saúde Mental
                        </li>{" "}
                        <li>Folga em dias específicos previamente planejado</li>
                      </ul>
                    </p>
                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}

              {this.props.jobpos === "frontreactlms" && (
                <div className="careerFront ">
                  <h5 className="bold text-alternate">
                    React Frontend
                  </h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                        Estamos iniciando um novo projeto em uma empresa norte americana que possui 20 anos de operaçäo em 6 estados Norte Americanos. Vamos criar novos produtos, desde o início, e estamos a procura de Desenvolvedores React.
                        <br /><br/>
                        Esse projeto é de uma empresa que é uma das maiores provedoras de pacotes de benefício, funcionando como uma corretora do setor, ela é subsidiaria à uma empresa nacional, que atende mais da metade dos estados americanos. Hoje trabalhamos com mais de 250 mil pessoas na plataforma.
                        <br /><br /> O foco será no desenvolvimento de um LMS (learning management system) para o setor de gerenciamento de benefícios empresariais.
                      </p>
                      <strong>Responsabilidades</strong>
                      <p>
                        <ul>
                          <li>
                            {" "}
                            O principal objetivo será desenvolver novas arquiteturas para os novos produtos.
                          </li>
                        </ul>
                      </p>
                      <strong>Requisitos </strong>
                      <p>
                        <ul>
                          <li>3 anos de experiência ou mais</li>

                          <li>Experiência com React</li>
                          <li>Conhecimentos com API / REST / JWT</li>
                          <li>Inglês Intermediário</li>
                        </ul>
                      </p>
                      <strong>Diferenciais</strong>
                      <p>
                        <ul>
                          <li>Experiência com Google Firebase e LMS/CMS</li>
                        </ul>
                      </p>
                      <strong>Benefícios</strong>

                      <ul>
                        <li> Modalidade PJ</li>{" "}
                        <li>Aulas de inglês Básico, Avançado e Conversação</li>{" "}
                        <li>10 dias pagos de férias</li>{" "}
                        <li>Horário flexível</li> <li>Home Office</li>{" "}


                        <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                        <li>
                          Webinars e ajuda de profissional para Saúde Mental
                        </li>{" "}
                        <li>Folga em dias específicos previamente planejado</li>
                      </ul>
                    </p>
                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}

              {this.props.jobpos === "phppost" && (
                <div className="careerFront ">
                  <h5 className="bold text-alternate">
                    PHP Developer Pl.|Sr.
                  </h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                        Esse cliente já está há mais de 20 anos no mercado norte-americano, operando em pelo menos 6 estados. O projeto tem como objetivo digitalizar, automatizar e otimizar os processos da empresa que conta com mais de 250 mil usuários ativos.
                      </p>
                      <strong>Responsabilidades</strong>
                      <p>
                        <ul>
                          <li>
                            {" "}
                            O principal objetivo será desenvolver novas funcionalidades para os novos produtos.
                          </li>
                        </ul>
                      </p>
                      <strong>Requisitos </strong>
                      <p>
                        <ul>
                          <li>Experiência com PHP e seus frameworks Laravel e/ou Symfony</li>
                            <li>Conhecimentos nos bancos de dados Postgres e MySQL</li>
                              <li>Inglês intermediário.</li>
                        </ul>
                      </p>
                     
                      <strong>Benefícios</strong>

                      <ul>
                        <li> Modalidade PJ</li>{" "}
                        <li>Aulas de inglês Básico, Avançado e Conversação</li>{" "}
                        <li>10 dias pagos de férias</li>{" "}
                        <li>Horário flexível</li> <li>Home Office</li>{" "}


                        <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                        <li>
                          Webinars e ajuda de profissional para Saúde Mental
                        </li>{" "}
                        <li>Folga em dias específicos previamente planejado</li>
                      </ul>
                    </p>
                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}


              {this.props.jobpos === "elixir" && (
                <div className="careerFront ">
                  <h5 className="bold text-alternate">
                    Elixir Developer
                  </h5> 
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                       
                        Buscamos profissionais em Elixir para trabalhar no back-end de um novo produto com uma equipe internacional.

                      </p>
                     
                      <strong>Requisitos </strong>
                      <p>
                        <ul>


                          <li> Banco de dados PostgreSQL</li>
                          <li> AWS</li>
                              <li> Cloudsearch</li>
                                <li>  SQS</li>
                                  <li>  Kinesis</li>
                                    <li>   Parameter Store</li>
                                      <li>   S3</li>
                                        <li>   Docker</li>
                                          <li>   Redis</li>
                                            <li>   GraphQL</li>
                                            <li>   CI/CD</li>
                                                <li>   Testes unitários e funcionais</li>
                                                  <li>   Metodologia ágil (Kanban)</li>
                                                    <li>   Versionamento no Github</li>
                                                      <li>   Qualidade e padrão de código </li>
                        </ul>
                      </p>
                      <strong>Diferenciais</strong>
                      <p>
                        <ul>
       
                          <li>Inglês Avançado</li>
                        </ul>
                      </p>
                      <strong>Benefícios</strong>

                      <ul>
                        <li> Modalidade PJ</li>{" "}
                        <li>Aulas de inglês Básico, Avançado e Conversação</li>{" "}
                        <li>10 dias pagos de férias</li>{" "}
                        <li>Horário flexível</li> <li>Home Office</li>{" "}
                       
                        <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                        <li>
                          Webinars e ajuda de profissional para Saúde Mental
                        </li>{" "}
                        <li>Folga em dias específicos previamente planejado</li>
                      </ul>
                    </p>
                  </div>
                  <div>
                   <a href="/elixir"> Saiba mais sobre o Elixir</a><br/><br/>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}


              {this.props.jobpos === "uiux" && (
                <div className="careerFront ">
                  <h5 className="bold text-alternate">
                    Designer de UI/UX <strong> Pleno/Senior </strong>
                  </h5>
                  <div className="card-body ">
                    <p>
                      <strong>Sobre a Vaga</strong>
                      <p>
                        Estamos com uma oportunidade para Designer de UI/UX para
                        atuar junto com o time de um dos nossos projetos norte-americanos!   O principal objetivo será trabalhar no desenvolvimento continuo das interfaces de um produto, juntamente com nosso time de desenvolvedores no Brasil.

                        <br />
                      </p>
                      <strong>Responsabilidades</strong>

                      <p>
                        <ul>
                          <li>  Investigar os requisitos de design da experiência do usuário  </li>
                          <li>Desenvolver e conceitualizar a estratégia de Design de UI / UX para o produto</li>
                          <li> Ilustrar idéias de design usando storyboards, fluxos de proceso e o mapa do produto</li>
                          <li>Criar conceitos de design e desenhos do produto (esboços e protótipos de alta fidelidade)</li>
                          <li> Construir / Apoiar na construção de mapas de jornadas e personas</li>
                          <li> Design e testes de elementos gráficos da interface do usuário (menus, guias, CTAs e widgets)</li>
                          <li> Desenvolver e entregar especificaçöes para desenvolvedores</li>
                        </ul>
                      </p>
                      <strong>Requisitos </strong>
                      <p>
                        <ul>
                          <li>Experiëncia como UX / UI web e mobile</li>

                          <li>Conhecimento em ferramentas para construçao de esboços e protótipos de alta fidelidade (Adobe XD, Figma, Zepling, etc) </li>
                          <li>Conhecimento em métricas de usabilidade (ex: framework HEART)</li>

                          <li>Habilidade em discutir e apresentar opções de Design em inglês </li>
                        </ul>
                      </p>
                      <strong>Diferenciais</strong>
                      <p>
                        <ul>
                          <li>Inglês Fluente</li>
                        </ul>
                      </p>
                      <strong>Benefícios</strong>

                      <ul>
                        <li> Modalidade PJ</li>{" "}
                        <li>Aulas de inglês Básico, Avançado e Conversação</li>{" "}
                        <li>10 dias pagos de férias</li>{" "}
                        <li>Horário flexível</li> <li>Home Office</li>{" "}
                       
                        <li>PLR Mensal</li> <li>Cursos e Certificações</li>{" "}
                        <li>
                          Webinars e ajuda de profissional para Saúde Mental
                        </li>{" "}
                        <li>Folga em dias específicos previamente planejado</li>
                      </ul>
                    </p>
                  </div>
                  <div>
                    Envie seu currículo para: &nbsp;
                    <a href={this.props.emailSubj}>
                      <strong>rh@upoutsourcing.com</strong>
                    </a>
                    <br />
                    <br />
                    <a
                      href="/careers"
                      class="more-link btn btn-outline-alternate more-link mt-0"
                    >
                      ... mais vagas
                    </a>
                    <br />
                  </div>
                </div>
              )}
            </Container>
          </section>
        </main>
        <DefaultFooter />
      </>
    );
  }
}
export default CareersDetails;
